import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> +[PTConvert FromCAD:in_filename:opts:]</div>
<div class="difference"><span class="status removed">Removed</span> PTWebFontDownloader</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebFontDownloader getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebFontDownloader initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebFontDownloader setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebFontDownloader dealloc]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTWebFontDownloader IsAvailable]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTWebFontDownloader EnableDownloads]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTWebFontDownloader DisableDownloads]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTWebFontDownloader PreCacheAsync]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTWebFontDownloader ClearCache]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTWebFontDownloader SetCustomWebFontURL:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebFontDownloader init]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTSDFDoc ImportObjsWithExcludeList:exclude_list:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnot GetCustomData:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnot SetCustomData:value:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnot DeleteCustomData:]</div>
<div class="difference"><span class="status added">Added</span> -[PTConversionOptions initWithValue:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert FromCAD:in_filename:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView HideAnnotation:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView ShowAnnotation:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl HideAnnotation:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl ShowAnnotation:]</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlColorPostProcessModeDidChangeNotification</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlColorPostProcessModeUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlBlackColorUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlWhiteColorUserInfoKey</div>
</div>

</div>



<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTLocalizedAnnotationNameFromType()</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationAnnotation.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.validForAdd</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.validForModify</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.validForRemove</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationAnnotationReplyViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotationReplyViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationAnnotationReplyViewController initWithCollaborationManager:]</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotationReplyViewController.collaborationManager</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotationReplyViewController.toolManager</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotationReplyViewController.currentAnnotationIdentifier</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationAnnotationViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotationViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationAnnotationViewController initWithCollaborationManager:]</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotationViewController.collaborationManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationDocumentViewController.collaborationReplyViewController</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationDocumentViewController.collaborationAnnotationViewController</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationDocumentViewController.collaborationAnnotationListHidden</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTCollaborationManager initWithExternalManager:toolManager:]</div>
<div class="difference"><span class="status removed">Removed</span> PTCollaborationManagerRemoteAnnotationAddedNotification</div>
<div class="difference"><span class="status removed">Removed</span> PTCollaborationManagerRemoteAnnotationModifiedNotification</div>
<div class="difference"><span class="status removed">Removed</span> PTCollaborationManagerRemoteAnnotationRemovedNotification</div>
<div class="difference"><span class="status removed">Removed</span> PTCollaborationManagerAnnotationUserInfoKey</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationServerCommunication.documentID</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager initWithToolManager:userId:]</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManager.toolManager</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager loadInitialRemoteAnnotation:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager loadInitialRemoteAnnotations:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager remoteAnnotationsAdded:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager remoteAnnotationsModified:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager remoteAnnotationsRemoved:]</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerRemoteAnnotationsAddedNotification</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerRemoteAnnotationsModifiedNotification</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerRemoteAnnotationsRemovedNotification</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerAnnotationsUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerIsInitialUserInfoKey</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTCollaborationServerCommunication userID]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull NSString *)userID</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, copy, nullable) NSString *userID</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.conversionOptions</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTNavigationListsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTNavigationListsViewController addListViewController:]</div>
<div class="difference"><span class="status added">Added</span> -[PTNavigationListsViewController removeListViewController:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTSelectionRectContainerView initWithPDFViewCtrl:forAnnot:withTool:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSelectionRectContainerView.borderView</div>
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectContainerView refreshLiveAppearance]</div>
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectContainerView initWithPDFViewCtrl:forAnnot:withAnnotEditTool:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTSelectionRectView initWithFrame:forAnnot:withTool:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectView refreshLiveAppearance]</div>
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectView initWithFrame:forAnnot:withAnnotEditTool:withPDFViewCtrl:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTextAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTTextAnnotationOptions.opensPopupOnTap</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextSelectTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTTextSelectTool DrawSelectionQuads:WithLines:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTextSelectTool DrawSelectionQuads:WithLines:WithDropAnimation:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTThumbnailSliderView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderView</div>
<div class="difference"><span class="status added">Added</span> -[PTThumbnailSliderView initWithPDFViewCtrl:]</div>
<div class="difference"><span class="status added">Added</span> -[PTThumbnailSliderView initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderView.pdfViewCtrl</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderView.toolManager</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderView.tracking</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderView.magnification</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderView.adjustsScrubbingSpeed</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderView.collectionView</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTThumbnailSliderViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTThumbnailSliderViewController setSliderValue:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTThumbnailSliderViewController setThumbnail:forPage:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTThumbnailSliderViewController initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderViewController.pdfViewCtrl</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderViewController.toolManager</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderViewController.slider</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderViewController.thumbnailSliderView</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderViewController.contentView</div>
<div class="difference"><span class="status added">Added</span> PTThumbnailSliderViewController.tracking</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTool keepToolAppearanceOnScreenWithImageView:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTToolManager enableCollaborationManager:]</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.collaborationManager</div>
<div class="difference"><span class="status removed">Removed</span> -[PTToolManager pageAddedAtPageNumber:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolEvents tool:handleFileSelected:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager pageAddedForPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:handleFileSelected:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolManager.textAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *textAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTTextAnnotationOptions *textAnnotationOptions</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolsMacros.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> #def PT_STRINGIFY__</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_STRINGIFY</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_NS_STRINGIFY</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_PASTE__</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_PASTE</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_C_ARRAY_SIZE</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_CONSTRUCTOR</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_CONSTRUCTOR_PRIORITY</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_DESTRUCTOR</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_DESTRUCTOR_PRIORITY</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_KEY</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_KEY_PATH</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_CLASS_KEY</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_CLASS_KEY_PATH</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_PROTOCOL_KEY</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_PROTOCOL_KEY_PATH</div>
<div class="difference"><span class="status removed">Removed</span> #def PT_NSStringFromBOOL</div>
<div class="difference"><span class="status removed">Removed</span> #def PTLog</div>
<div class="difference"><span class="status removed">Removed</span> #def PrivateLog</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}